import { faFile, faFileArchive, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOURS } from '@gtintel/platform-utils';
const FileIcon = ({
  extension,
  style
}) => {
  const iconStyle = style ? style : {
    color: COLOURS.black,
    fontSize: '30px',
    marginRight: '8px'
  };
  let icon = faFile;
  switch (extension) {
    case 'pdf':
      icon = faFilePdf;
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
      icon = faFileImage;
      break;
    case 'xlsx':
    case 'xls':
      icon = faFileExcel;
      break;
    case 'doc':
    case 'docx':
      icon = faFileWord;
      break;
    case 'ppt':
    case 'pptx':
      icon = faFilePowerpoint;
      break;
    case 'zip':
    case '7z':
    case 'rar':
      icon = faFileArchive;
      break;
    default:
      icon = faFile;
      break;
  }
  return <FontAwesomeIcon icon={icon} data-testid="file-icon" style={iconStyle} />;
};
export default FileIcon;