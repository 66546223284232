import { useStyletron } from 'baseui';
import { Heading, HeadingLevel } from 'baseui/heading';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default (({
  errorText
}) => {
  const [css] = useStyletron();
  return <div className={css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  })}>
      <div>
        <FontAwesomeIcon icon={faWarning} size="lg" />
        <HeadingLevel>
          <Heading>{errorText}</Heading>
        </HeadingLevel>
      </div>
    </div>;
});