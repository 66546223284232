import { useStyletron } from 'baseui';
import { COLOURS } from '@gtintel/platform-utils';
export default (({
  children,
  styleOverrides,
  testId
}) => {
  const [css] = useStyletron();
  const styles = {
    background: '#fff',
    border: `2px solid ${COLOURS.borderGray}`,
    borderRadius: '4px',
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: '28px 0',
    padding: '14px',
    p: {
      margin: '0'
    },
    ...styleOverrides
  };
  return <div className={css(styles)} data-testid={testId || 'card'}>
      {children}
    </div>;
});