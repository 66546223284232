import { useStyletron } from 'baseui';
import { hexToRGB } from '@gtintel/platform-utils';
export default (({
  children,
  colour,
  title
}) => {
  const [css] = useStyletron();
  return <div className={css({
    background: hexToRGB(colour, 0.1),
    border: `2px solid ${colour}`,
    borderRadius: '18px',
    color: colour,
    padding: '4px 8px',
    width: 'fit-content'
  })} title={title}>
      {children}
    </div>;
});